<template>
  <div class="Teamwork" v-fixed>
    <div class="topbar">
      <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title">{{$t("我的預約")}}</div>
    </div>
    <div class="content">
          <div class="games">
            <div class="info" v-for="item in bookingGames">
              <div class="left">
                <img :src="item.icon" />
                <div>
                  <div class="t1">{{ item.name }}</div>
                  <div class="t2">&nbsp;</div>
                </div>
              </div>
              <div class="right">
                <div class="down" style="cursor: pointer;" @click="booking(item)">{{ (item.status == 1 && item.is_show) ?
                  $t("下載") : $t("已预约") }}</div>
              </div>
              
             
            </div>
            <div class="d-flex flex-column justify-center align-center" style="height: 80%;width: 100%;" v-if="!bookingGames.length">
              <img style="width: 200px" src="../assets/imgs/none.png" alt="" />
              <div style="font-size: 18px;color: #9dafb6;">{{ $t("您暫未预约游戏") }}</div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      bookingGames: [],
    };
  },
  created() {

    this.getBookingGame();
  },
  methods: {
    booking(item) {
      if (item.is_show && item.status == 1) {
        this.$router.push({ name: 'GameDetails', query: { id: item.id } })
        return
      }
      this.$get("/api/Home/bookingGame", {
        gameId: item.id,
        booked: false
      }).then((res) => {
        this.getBookingGame()
      });
    },
    getBookingGame() {
      this.$get("/api/Home/getBookingGame").then((res) => {
        this.bookingGames = res.data;
      });
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Teamwork {
  padding-top: 46px;
  background: #F0F6F9;
  height: 100%;
  .topbar {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: #F0F6F9;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  .content {
    .games {
      margin-top: 1.14667rem;
    display: flex;
    flex-direction: column;
    padding: 0 0.66667rem;

          .info {
            padding: 0.29333rem 0.32rem;
    height: 2.34667rem;
    display: flex;
    margin-bottom: 0.53333rem;
    background: rgba(255, 255, 255, 0.3) !important;
    border: 0.02667rem solid #FFFFFF !important;
    border-radius: 0.26667rem;
    justify-content: space-between;

            .left {
              img {
                width: 1.33333rem;
    margin-right:0.26667rem;
    box-shadow: 0 0.26667rem 0.53333rem #d9d0e2;
    border-radius: 0.26667rem;
    height: 1.33333rem;
              }

              display: flex;
    flex-direction: row;

              .t1 {
                font-family: 'Source Han Sans CN';
                font-style: normal;
                font-size: 18px;
                line-height: 20px;
                color: #485573;
              }

              .t2 {

                font-family: 'Source Han Sans CN';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                color: #A4A2B8;
              }
            }

            .right {
              display: flex;
    align-items: center;
              .down{
                background: #F84E84;
    border-radius: 0.13333rem;
    font-size: 0.37333rem;
    line-height: 0.53333rem;
    text-align: center;
    padding: 0.05333rem 0.45333rem;
    color: #FFFFFF;
            }
            }

            
          }
        }
  }
}
</style>